import { BigNumber } from 'bignumber.js'
import { wrapNegativeNumber } from '~/composables/support/localization'

/**
 * Arbitrary precision decimal wrapped around bignumber.js
 *
 * The main reason for this class is provided a subset of BigNumber's
 * functionality and prevent mistakes by enforcing only using strings as input
 * and output. Forcing to only use string allows us to avoid numbers that cannot
 * be properly represented in floating point to cause errors.
 *
 * Also, we only allow calculations in base-10 so remove all those parameters
 */
export class Decimal {
    private readonly _value: BigNumber

    constructor(value: string) {
        if (typeof value !== 'string') {
            console.log(typeof value, value)
            throw new Error('Only strings are allowed')
        }
        this._value = new BigNumber(value)
    }

    static zero() {
        return new Decimal('0')
    }

    static one() {
        return new Decimal('1')
    }

    private static wrap(value: BigNumber) {
        const wrapped = Decimal.zero()
        // @ts-ignore : Easiest way to wrap value
        wrapped._value = value
        return wrapped
    }

    isEqualTo(value: Decimal) {
        return this._value.isEqualTo(value._value)
    }

    isNotEqualTo(value: Decimal) {
        return !this._value.isEqualTo(value._value)
    }

    isGreaterThan(value: Decimal) {
        return this._value.isGreaterThan(value._value)
    }

    isGreaterThanOrEqualTo(value: Decimal) {
        return this._value.isGreaterThanOrEqualTo(value._value)
    }

    isLessThan(value: Decimal) {
        return this._value.isLessThan(value._value)
    }

    isLessThanOrEqualTo(value: Decimal) {
        return this._value.isLessThanOrEqualTo(value._value)
    }

    invertSign() {
        return Decimal.wrap(this._value.multipliedBy(-1))
    }

    plus(value: Decimal) {
        return Decimal.wrap(this._value.plus(value._value))
    }

    minus(value: Decimal) {
        return Decimal.wrap(this._value.minus(value._value))
    }

    multipliedBy(value: Decimal) {
        return Decimal.wrap(this._value.multipliedBy(value._value))
    }

    dividedBy(value: Decimal) {
        return Decimal.wrap(this._value.dividedBy(value._value))
    }

    decimalPlaces(decimalPlaces: number) {
        return Decimal.wrap(
            this._value.decimalPlaces(decimalPlaces, BigNumber.ROUND_HALF_UP)
        )
    }

    toFixed(decimalPlaces: number) {
        return this._value.toFixed(decimalPlaces, BigNumber.ROUND_HALF_UP)
    }

    isZero() {
        return this._value.isZero()
    }

    isNotZero() {
        return !this._value.isZero()
    }

    // INCLUDES ZERO!
    isPositive() {
        return this._value.isPositive()
    }

    isPositiveAndNotZero() {
        return this._value.isPositive() && !this._value.isZero()
    }

    isNegative() {
        return this._value.isNegative()
    }

    abs() {
        return Decimal.wrap(this._value.abs())
    }

    formatCurrency(precision = 2, useParenthesisForNegative = true) {
        const locale = 'en-US'
        const currencyFormat = 'USD'
        const numberFormatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currencyFormat
        })

        if (useParenthesisForNegative && this.isNegative()) {
            const valueString = this.abs().toFixed(precision)
            // @ts-expect-error : this function does accept string and we want arbitrary precision
            return wrapNegativeNumber(numberFormatter.format(valueString))
        } else {
            const valueString = this.toFixed(precision)
            // @ts-expect-error : this function does accept string and we want arbitrary precision
            return numberFormatter.format(valueString)
        }
    }
}
